import '@/styles/globals.css'
import '@/styles/tailwindApply.css'
import type { AppProps } from 'next/app'
import 'regenerator-runtime/runtime'
import Link from 'next/link'

import styles from '@/styles/LoveIsWar.module.css'

import Script from 'next/script'

const gtag = {
  GA_MEASUREMENT_ID: process.env.NEXT_PUBLIC_GA_ID
}

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_MEASUREMENT_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());
 
           gtag('config', '${gtag.GA_MEASUREMENT_ID}');
           `,
        }}
      />

      {/*<Script src="https://cdn.jsdelivr.net/npm/onnxruntime-web/dist/ort.js" />*/}
      {/*<Script src="https://cdn.jsdelivr.net/npm/@ricky0123/vad-web@0.0.7/dist/bundle.min.js" />*/}
      <Component {...pageProps} ></Component>

      <Footer />
    </>
  )
}

const Footer = () => {
  return(
    <footer className="border-top-5 w-full text-center p-5">
      <Link href={`/love-is-war`} className='mx-3'>
        Home
      </Link>
      <Link href='/privacy-policy.html' className='mx-3' rel="noopener noreferrer" target="_blank">
        PrivacyPolicy
      </Link>
      <span className='mx-5'>
        VOICEVOX:雨晴はう
      </span>

      {/*<span>*/}
      {/*  <Link href='https://twitter.com/share' rel="noopener noreferrer" target="_blank"*/}
      {/*        className='twitter-share-button mx-3'*/}
      {/*        data-text="バズブログです！よろしく"*/}
      {/*        data-url="https://droid.studio/love-is-war"*/}
      {/*        rel="noopener noreferrer" target="_blank"*/}
      {/*        data-show-count="false"*/}
      {/* >Tweet*/}
      {/*  </Link>*/}
      {/*  <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>*/}
      {/*</span>*/}
      <br />
      <br />
      <Link href={`https://twitter.com/taiyop`} rel="noopener noreferrer" target="_blank" className='mx-3'>
        @taiyop
      </Link>

      <Link href={`https://twitter.com/2gmon`} rel="noopener noreferrer" target="_blank" className='mx-3'>
        @2gmon
      </Link>
    </footer>
  )
}
